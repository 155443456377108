<section class="section">
	<div class="container">
		<app-arrow url="/minneapolis/outside"></app-arrow>
		<h1 class="title">Do You Want:</h1>
		<div class="columns">
			<div class="column" (click)="selectSweet()">
				<div class="card content">
					<h3>Sweet</h3>
				</div>
			</div>
			<div class="column" (click)="selectSavory()">
				<div class="card content">
					<h3>Savory</h3>
				</div>
			</div>
			<div class="column" (click)="selectLiquids()">
				<div class="card content">
					<h3>Liquids</h3>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="section" style="padding-top:0;padding-bottom:0;">
	<div class="container">
		<hr />
	</div>
</section>
<section class="section">
	<div class="container">
		<div class="sweets" *ngIf="isSweet">
			<div class="columns is-multiline">
				<div class="column is-one-third sweet" *ngFor="let s of sweets">
					<a href="{{s.link}}" target="_blank">
						<div class="card content">
							<h3>{{s.name}}</h3>
						</div>
					</a>
				</div>
			</div>
		</div>

		<div class="savory-items" *ngIf="isSavory">
			<div class="columns is-multiline">
				<div class="column is-one-third sweet" *ngFor="let s of savoryItems">
					<a href="{{s.link}}" target="_blank">
						<div class="card content">
							<h3>{{s.name}}</h3>
						</div>
					</a>
				</div>
			</div>
		</div>

		<div class="liquids" *ngIf="isLiquids">
			<div class="columns is-multiline">
				<div class="column is-one-third bar" *ngFor="let b of bars">
					<a href="{{b.link}}" target="_blank">
						<div class="card content">
							<h3>{{b.name}}</h3>
						</div>
					</a>
				</div>
			</div>
		</div>

	</div>
</section>
