import { Podcast } from './podcast';

export const PODCASTS: Podcast[] = [
	{
		id: 1,
		title: 'Code Switch',
		link: 'https://www.npr.org/podcasts/510312/codeswitch'
	}, {
		id: 2,
		title: 'Identity Politics',
		link: 'http://identitypoliticspod.com/about/'
	}, {
		id: 3,
		title: 'Truth Be Told',
		link: 'https://www.deartbt.com/episodes'
	}, {
		id: 4,
		title: 'Louder Than A Riot',
		link: 'https://www.npr.org/podcasts/510357/louder-than-a-riot'
	}
];
