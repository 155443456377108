<section class="section">
	<div class="container">
		<app-arrow url="/los-angeles/inside"></app-arrow>
		<h1 class="title">Which Would You Rather:</h1>
		<div class="columns">
			<div class="column" (click)="selectRead()">
				<div class="card content">
					<h3>Read</h3>
				</div>
			</div>
			<div class="column" (click)="selectWatch()">
				<div class="card content">
					<h3>Watch</h3>
				</div>
			</div>
			<div class="column" (click)="selectListen()">
				<div class="card content">
					<h3>Listen</h3>
				</div>
			</div>
		</div>

	</div>
</section>
<section class="section" style="padding-top:0;padding-bottom:0;">
	<div class="container">
		<hr />
	</div>
</section>
<section class="section">
	<div class="container">

		<div class="books" *ngIf="isRead">
			<h2 class="title is-4">Check Out:</h2>
			<div class="columns is-multiline">
				<div class="column is-one-third book" *ngFor="let book of books"
					[class.selected]="book === selectedBook"
					(click)="onSelect(book)">
					<a href="{{book.link}}" target="_blank">
						<div class="card content">
							<h3>{{book.title}}</h3>
							<small>By {{book.author}}</small>
						</div>
					</a>
				</div>
			</div>
		</div>

		<div class="movies" *ngIf="isWatch">
			<h2 class="title is-4">Check Out:</h2>
			<div class="columns is-multiline">
				<div class="column is-one-third movie" *ngFor="let movie of movies">
					<a href="{{movie.link}}" target="_blank">
						<div class="card content">
							<h3>{{movie.title}}</h3>
							<small>{{movie.streaming}}</small>
						</div>
					</a>
				</div>
			</div>
		</div>

		<div class="podcasts" *ngIf="isListen">
			<h2 class="title is-4">Check Out:</h2>
			<div class="columns is-multiline">
				<div class="column is-one-third podcast" *ngFor="let p of podcasts">
					<a href="{{p.link}}" target="_blank">
						<div class="card content">
							<h3>{{p.title}}</h3>
						</div>
					</a>
				</div>
			</div>
		</div>

	</div>
</section>
