import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LosAngelesComponent } from './los-angeles/los-angeles.component';
import { MinneapolisComponent } from './minneapolis/minneapolis.component';
import { EatComponent } from './los-angeles/inside/eat/eat.component';
import { PlayComponent } from './los-angeles/inside/play/play.component';
import { LearnComponent } from './los-angeles/inside/learn/learn.component';
import { EatOutsideComponent } from './los-angeles/outside/eat/eat.component';
import { PlayOutsideComponent } from './los-angeles/outside/play/play.component';
import { LearnOutsideComponent } from './los-angeles/outside/learn/learn.component';
//
import { MPLSEatComponent } from './minneapolis/inside/eat/eat.component';
import { MPLSPlayComponent } from './minneapolis/inside/play/play.component';
import { MPLSLearnComponent } from './minneapolis/inside/learn/learn.component';
import { MPLSEatOutsideComponent } from './minneapolis/outside/eat/eat.component';
import { MPLSPlayOutsideComponent } from './minneapolis/outside/play/play.component';
import { MPLSLearnOutsideComponent } from './minneapolis/outside/learn/learn.component';
//
import { ElsewhereComponent } from './elsewhere/elsewhere.component';
import { EEatComponent } from './elsewhere/eat/eat.component';
import { EPlayComponent } from './elsewhere/play/play.component';
import { ELearnComponent } from './elsewhere/learn/learn.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'los-angeles', component: LosAngelesComponent,
    children: [
      {
        path: 'inside', loadComponent: () => import('./los-angeles/inside/inside.component').then(mod => mod.InsideComponent),
        children: [
          { path: 'eat', component: EatComponent },
          { path: 'play', component: PlayComponent },
          { path: 'learn', component: LearnComponent }
        ]
      },
      {
        path: 'outside', loadComponent: () => import('./los-angeles/outside/outside.component').then(mod => mod.OutsideComponent),
        children: [
          { path: 'eat', component: EatOutsideComponent },
          { path: 'play', component: PlayOutsideComponent },
          { path: 'learn', component: LearnOutsideComponent }
        ]
      },
    ]
  },
  {
    path: 'minneapolis', component: MinneapolisComponent,
    children: [
      {
        path: 'inside', loadComponent: () => import('./minneapolis/inside/inside.component').then(mod => mod.InsideComponent),
        children: [
          { path: 'eat', component: MPLSEatComponent },
          { path: 'play', component: MPLSPlayComponent },
          { path: 'learn', component: MPLSLearnComponent }
        ]
      },
      {
        path: 'outside', loadComponent: () => import('./minneapolis/outside/outside.component').then(mod => mod.OutsideComponent),
        children: [
          { path: 'eat', component: MPLSEatOutsideComponent },
          { path: 'play', component: MPLSPlayOutsideComponent },
          { path: 'learn', component: MPLSLearnOutsideComponent }
        ]
      },
    ]
  },
  {
    path: 'elsewhere', component: ElsewhereComponent,
    children: [
      { path: 'eat', component: EEatComponent },
      { path: 'play', component: EPlayComponent },
      { path: 'learn', component: ELearnComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
