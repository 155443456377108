import { Component, Inject } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel, NavigationError, NavigationStart, Event, RouterLink, RouterOutlet } from '@angular/router';
import { Location, NgIf, DOCUMENT } from '@angular/common';
import { ArrowComponent } from '../shared/arrow/arrow.component';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
    selector: 'app-minneapolis',
    templateUrl: './minneapolis.component.html',
    styleUrls: ['./minneapolis.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, RouterOutlet, ArrowComponent]
})
export class MinneapolisComponent {
    location: Location;
    isCategory = false;
    categories = [
        'inside',
        'outside'
    ];
    insideCategories = [
        'eat',
        'play',
        'learn'
    ];

    constructor(location: Location,
        @Inject(DOCUMENT) private document: Document,
        private router: Router) {
        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart || event instanceof NavigationEnd ||
                event instanceof NavigationCancel || event instanceof NavigationError) {

                const isCategoryPage =
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[0]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[0] + '/' + this.insideCategories[0]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[0] + '/' + this.insideCategories[1]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[0] + '/' + this.insideCategories[2]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[1]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[1] + '/' + this.insideCategories[0]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[1] + '/' + this.insideCategories[1]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[1] + '/' + this.insideCategories[2]);

                if (isCategoryPage) {
                    document.body.classList.add('category-page');
                    this.isCategory = true;
                } else {
                    document.body.classList.remove('category-page');
                    this.isCategory = false;
                }

                const isInsidePage =
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[0]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[0] + '/' + this.insideCategories[0]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[0] + '/' + this.insideCategories[1]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[0] + '/' + this.insideCategories[2]);

                if (isInsidePage) {
                    document.body.classList.add('inside-page');
                } else {
                    document.body.classList.remove('inside-page');
                }

                const isOutsidePage =
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[1]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[1] + '/' + this.insideCategories[0]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[1] + '/' + this.insideCategories[1]) ||
                    location.isCurrentPathEqualTo('/minneapolis/' + this.categories[1] + '/' + this.insideCategories[2]);

                if (isOutsidePage) {
                    document.body.classList.add('outside-page');
                } else {
                    document.body.classList.remove('outside-page');
                }

            }
        });

        // setTimeout(() => {
        //     gsap.set(['.card', '.title', '.welcome-copy'], { y: 30, scale: 0.9 });
        //     this.animate();
        // }, 700);

    }

    animate(): void {
        let project_item = this.document.querySelectorAll('.card');
        gsap.set([project_item, '.title'], { y: 100 });

        ScrollTrigger.batch(project_item, {
            onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, scale: 1, stagger: { each: 0.05, grid: [1, 2] }, overwrite: true })
        });
        gsap.to(['.title', '.welcome-copy'], { opacity: 1, y: 0, scale: 1, stagger: { each: 0.05, grid: [1, 2] }, overwrite: true });
    }


}
