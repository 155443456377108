<section class="section">
	<div class="container">
		<app-arrow url="/los-angeles/inside"></app-arrow>
		<h1 class="title hidden">would you like to:</h1>
		<div class="columns">
			<div class="column">
				<a href="https://www.doordash.com/cuisine/black-owned-near-me/" target="_blank">
					<div class="card content">
						<h3>DoorDash Black-Owned Near Me</h3>
					</div>
				</a>
			</div>
		</div>
	</div>
</section>
