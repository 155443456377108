import { Event } from './event';

export const EVENTS: Event[] = [
	{
		id: 1,
		title: 'Father\'s of Jazz, Juneteenth Swing Seminar & Dance Class',
		link: 'https://www.eventbrite.com/e/fathers-of-jazz-juneteenth-swing-seminar-dance-class-tickets-907488742257',
		date: 'June 14'
	},
	{
		id: 2,
		title: 'Juneteenth Celebration with T-Pain',
		copy: '@ Hollywood Bowl',
		link: 'https://www.hollywoodbowl.com/events/performances/2931/2024-06-19/juneteenth-celebration-t-pain-plus-special-guests',
		date: 'June 19'
	},
	{
		id: 3,
		title: 'Kickoff Juneteenth Comedy',
		link: 'https://www.eventbrite.com/e/kickoff-juneteenth-comedy-tickets-911500050187',
		date: 'June 14'
	}
];
