<section class="section">
	<div class="container">
		<div class="welcome-container" *ngIf="isWelcome && !hasBeenWelcomed">
			<div class="welcome-copy content">
				<p><strong>Happy Juneteenth!</strong> With this day, we commemorate the date in 1865 when the enslaved
					people living in
					Galveston, Texas received the news that they were freed.</p>

				<p>Please note that this news was received over two years after President Lincoln signed the
					Emancipation Proclamation, a year after the senate passed the 13th amendment, and three months after
					the end of the Civil War, and slavery did not end here.</p>

				<p>President Biden first recognized Juneteenth as a federal holiday in 2021 by signing the Juneteenth
					National Independence Day Act into law after the efforts of Lula Briggs Galloway, Opal Lee, and
					other activists.</p>

				<p>Though there’s a grim history, this day continues to have a foundation of hope, achievement, and
					celebration within the Black community and within American culture. We hope you take the time to
					recognize and honor the significance surrounding this day and have put together a few ways for you
					and your loved ones to do just that.</p>
			</div>
			<div class="welcome-card card" (click)="selectLocation()">
				<h1 class="title">Let’s Get Started</h1>
			</div>
		</div>

		<div class="location-container" *ngIf="isLocation">
			<h1 class="title">Where Do You Rep?</h1>

			<div class="columns">
				<div class="column">
					<a routerLink="/los-angeles">
						<div class="location-card card content">
							<h3>Los Angeles, CA</h3>
						</div>
					</a>
				</div>

				<div class="column">
					<a routerLink="/minneapolis">
						<div class="location-card card content">
							<h3>Minneapolis, MN</h3>
						</div>
					</a>
				</div>

				<div class="column">
					<a routerLink="/elsewhere">
						<div class="location-card card content">
							<h3>Elsewhere</h3>
						</div>
					</a>
				</div>

			</div>

		</div>

	</div>
</section>
