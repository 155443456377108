import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Router, NavigationEnd, NavigationCancel,
  NavigationError, NavigationStart, Event, ActivatedRoute,
  RouterLinkActive, RouterLink, RouterOutlet
} from '@angular/router';

@Component({
  selector: 'app-arrow',
  standalone: true,
  imports: [CommonModule, RouterLinkActive, RouterLink, RouterOutlet],
  templateUrl: './arrow.component.html',
  styleUrls: ['./arrow.component.scss']
})
export class ArrowComponent {
  @Input() url: string;

  constructor(private router: Router) { }

  onClick(url) {
    this.router.navigate(['' + url + '']);
  }

}
