import { Movie } from './movie';

export const MOVIES: Movie[] = [
	{
		id: 1,
		title: 'Brown Sugar Burlesque',
		copy: '',
		link: 'https://www.eventbrite.com/e/brown-sugar-burlesque-tickets-907174733047',
		date: 'June 16'
	},
	{
		id: 2,
		title: 'FREEDOM Exhibition',
		copy: '@ESMoA',
		link: 'https://esmoa.org/event/experience-57-freedom-opening/',
		date: 'June 16'
	},
	{
		id: 3,
		title: 'Curatorial Walkthrough: Black California Dreamin',
		copy: '@ California African American Museum',
		link: 'https://caamuseum.org/programs/talks-and-workshops/curatorial-walkthrough-black-california-dreamin',
		date: 'June 19'
	}
];
