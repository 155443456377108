import { Event } from './event';

export const EVENTS: Event[] = [
	{
		id: 1,
		name: 'Community BBQ + Basketball Tournament',
		link: 'https://www.eventbrite.com/e/change-starts-with-community-juneteenth-3-on-3-basketball-tournament-tickets-628282609197',
		date: 'June 16'
	}, {
		id: 2,
		name: 'Black Bodies In Motion',
		link: 'https://midtownglobalmarket.org/new-events/2023/6/17/juneteenth-celebration',
		date: 'June 17'
	}, {
		id: 3,
		name: 'Soul of the Southside Festival',
		link: 'https://soulofthesouthside.com/',
		date: 'June 19'
	}, {
		id: 4,
		name: 'Juneteenth Jubilee: Black Burlesque Show',
		link: 'https://thecapri.org/event/tre-da-marc-presents-juneteenth-jubilee-a-black-burlesque-show/',
		date: 'June 16'
	}
];
