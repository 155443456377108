import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { ArrowComponent } from '../../../shared/arrow/arrow.component';
@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss'],
  standalone: true,
  imports: [NgIf, ArrowComponent]
})
export class PlayComponent {
  isTrashTalk = false;
  isCivil = false;

  constructor(private router: Router) { }

  back(): void {
    this.router.navigate(['/los-angeles/inside']);
  }

  selectTrashTalk(): void {
    this.isTrashTalk = true;
    this.isCivil = false;
  }
  selectCivil(): void {
    this.isCivil = true;
    this.isTrashTalk = false;
  }
}
