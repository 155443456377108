import { Sweet } from './sweet';

export const SWEETS: Sweet[] = [
	{
		id: 1,
		name: 'La Boulangerie Marguerite',
		link: 'https://la-marg.com/collections'
	}, {
		id: 2,
		name: '2 Scoops Eatery',
		link: 'https://2scoopseatery.com/#https://2scoopseatery.com/#!/services'
	}, {
		id: 3,
		name: 'Green Garden Bakery',
		link: 'https://www.instagram.com/greengardenbakerympls/'
	}, {
		id: 4,
		name: 'The Donut Trap',
		link: 'https://www.instagram.com/thedonuttrap/'
	}
];
