import { Savory } from './savory';

export const SAVORYITEMS: Savory[] = [
	{
		id: 1,
		name: 'Alta Adams',
		link: 'https://altaadams.com/menu-alt/'
	}, {
		id: 2,
		name: 'Rosalind’s',
		link: 'https://www.rosalindsla.com/menu'
	}, {
		id: 3,
		name: 'Poppy + Rose',
		link: 'https://www.poppyandrose.com/'
	}, {
		id: 4,
		name: 'Sky’s Gourmet Tacos',
		link: 'https://www.skysgourmettacos.com/menu'
	}, {
		id: 5,
		name: 'Brandoni Pepperoni',
		link: 'https://www.brandoni-pepperoni.com/menu-1'
	}
];
