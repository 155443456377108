import { Event } from './event';

export const EVENTS: Event[] = [
	{
		id: 1,
		title: 'University of Minnesota Juneteenth Celebration',
		link: 'https://juneteenth.umn.edu/',
		date: 'June 15'
	}, {
		id: 3,
		title: 'Echo In The Distance Book Launch',
		link: 'https://modernwell.co/functions/echo-in-the-distance-book-launch-with-author-shayla-michelle/',
		date: 'June 19'
	}
];
