<section class="section">
	<div class="container">
		<app-arrow url="/los-angeles/outside"></app-arrow>
		<h1 class="title hidden">would you like to:</h1>
		<div class="columns">
			<div class="column" (click)="selectWatch()">
				<div class="card content">
					<h3>Look</h3>
				</div>
			</div>
			<div class="column" (click)="selectListen()">
				<div class="card content">
					<h3>Listen</h3>
				</div>
			</div>
		</div>

	</div>
</section>
<section class="section" style="padding-top:0;padding-bottom:0;">
	<div class="container">
		<hr />
	</div>
</section>
<section class="section">
	<div class="container">
		<div class="movies" *ngIf="isWatch">
			<div class="columns is-multiline">
				<div class="column is-one-third movie" *ngFor="let movie of movies">
					<a href="{{movie.link}}" target="_blank">
						<div class="card content">
							<h3>{{movie.title}}</h3>
							<p>{{movie.copy}}</p>
							<small>{{movie.date}}</small>
						</div>
					</a>
				</div>
			</div>
		</div>

		<div class="events" *ngIf="isListen">
			<div class="columns is-multiline">
				<div class="column is-one-third event" *ngFor="let e of events">
					<a href="{{e.link}}" target="_blank">
						<div class="card content">
							<h3>{{e.title}}</h3>
							<small>{{e.date}}</small>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</section>
