import { Event } from './event';

export const EVENTS: Event[] = [
	{
		id: 1,
		name: 'Juneteenth Wellness Day',
		link: 'https://caamuseum.org/programs/talks-and-workshops/juneteenth-prosperity-market-farmers-market',
		copy: 'California African American Museum',
		date: 'June 18'
	}, {
		id: 2,
		name: 'Leimert Park Juneteenth Festival',
		link: 'https://www.leimertparkjuneteenth.com/',
		date: 'June 19'
	}, {
		id: 3,
		name: 'Santa Monica Annual Juneteenth Celebration',
		link: 'https://santamonica.gov/events/4zy0npxfwtm9gwz27a6wfzhf5k/202306171300',
		date: 'June 17'
	}, {
		id: 5,
		name: 'Melrose Trading Post Juneteenth Celebration',
		link: 'https://www.eventbrite.com/e/second-annual-juneteenth-community-celebration-tickets-627490570187?aff=erelexpmlt',
		date: 'June 18'
	}, {
		id: 6,
		name: 'Make Waist Beads by Ayodele',
		copy: '@ Runway Boutique LA',
		link: 'https://www.eventbrite.com/e/waist-beads-by-ayodele-at-runway-boutique-la-tickets-906162375057',
		date: 'June 19'
	}, {
		id: 7,
		name: 'Black Rocks',
		copy: '@ LA Boulders. Please note this specific event is dedicated to Black and Pan-African communities',
		link: 'https://www.eventbrite.com/e/black-rocks-tickets-906132836707',
		date: 'June 14'
	}, {
		id: 8,
		name: 'Black on the Block Juneteenth Festival',
		link: 'https://www.blackxtheblock.com/event-details-registration/juneteenth-festival',
		date: 'June 16'
	}, {
		id: 9,
		name: 'Hotwater Cornbread Festival',
		link: 'https://www.instagram.com/p/C7CtSowxYPn/?hl=en',
		date: 'June 15'
	}
];
