import { Bar } from './bar';

export const BARS: Bar[] = [
	{
		id: 1,
		name: 'The Undergrind Cafe',
		link: 'https://undergrindcafe.com/'
	}, {
		id: 2,
		name: 'Adams Wine Shop',
		link: 'https://adamswineshop.com/'
	}, {
		id: 3,
		name: '1010 Wine',
		link: 'https://1010wineandevents.com/'
	}, {
		id: 4,
		name: 'Bloom and Plume',
		link: 'https://www.instagram.com/bloomandplumecoffee/?hl=en'
	}, {
		id: 5,
		name: 'Super Domestic Coffee',
		link: 'https://www.instagram.com/superdomesticcxc/?hl=en'
	}, {
		id: 6,
		name: 'Obet and Del’s',
		link: 'https://www.instagram.com/obetanddels/?hl=en'
	}, {
		id: 7,
		name: 'Flowerboy Project',
		link: 'https://www.instagram.com/flowerboyproject/'
	}
];
