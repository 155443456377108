import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { EVENTS } from './events';
import { ArrowComponent } from '../../../shared/arrow/arrow.component';

@Component({
  selector: 'app-mpls-play',
  standalone: true,
  imports: [CommonModule, ArrowComponent],
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss']
})
export class MPLSPlayOutsideComponent {
  events = EVENTS;

  constructor(private router: Router) { }

  back(): void {
    this.router.navigate(['/minneapolis/outside']);
  }
}
