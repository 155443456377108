import { Component, Inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Location, NgIf, DOCUMENT } from '@angular/common';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [RouterLink, NgIf]
})
export class HomeComponent {
  isWelcome = true;
  isLocation = false;
  hasBeenWelcomed = false;

  constructor(@Inject(DOCUMENT) private document: Document,
    router: Router) {
    this.checkCookie();
    setTimeout(() => {
      gsap.set(['.card', '.title', '.welcome-copy'], { y: 30, scale: 0.9 });
      this.animate();
    }, 900);
  }

  selectLocation(): void {
    this.isLocation = true;
    this.isWelcome = false;
    this.hasBeenWelcomed = true;
    sessionStorage.setItem('hasBeenWelcomed', 'true');
    setTimeout(() => { this.animate(); }, 700);
  }
  selectWelcome(): void {
    this.isWelcome = true;
    this.isLocation = false;
  }

  animate(): void {
    let project_item = this.document.querySelectorAll('.card');
    gsap.set([project_item, '.title'], { y: 100 });

    ScrollTrigger.batch(project_item, {
      onEnter: batch => gsap.to(batch, { opacity: 1, y: 0, scale: 1, stagger: { each: 0.05, grid: [1, 2] }, overwrite: true })
    });
    gsap.to(['.title', '.welcome-copy'], { opacity: 1, y: 0, scale: 1, stagger: { each: 0.05, grid: [1, 2] }, overwrite: true });
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  getCookie(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  checkCookie() {
    const cookied = sessionStorage.getItem('hasBeenWelcomed');
    if (cookied) {
      this.hasBeenWelcomed = true;
      this.isLocation = true;
    } else {
      this.hasBeenWelcomed = false;
      this.isLocation = false;
    }
  }

}
