import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';
import { Book } from './book';
import { BOOKS } from './books';
import { Movie } from './movie';
import { MOVIES } from './movies';
import { Podcast } from './podcast';
import { PODCASTS } from './podcasts';
import { ArrowComponent } from '../../shared/arrow/arrow.component';

@Component({
  selector: 'app-e-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, ArrowComponent]
})
export class ELearnComponent {
  books = BOOKS;
  selectedBook!: Book;
  movies = MOVIES;
  selectedMovie!: Movie;
  podcasts = PODCASTS;
  selectedPodcast!: Podcast;
  isRead = false;
  isWatch = false;
  isListen = false;
  constructor(private router: Router) { }

  onSelect(book: Book): void {
    this.selectedBook = book;
  }

  selectRead(): void {
    this.isRead = true;
    this.isWatch = false;
    this.isListen = false;
  }
  selectWatch(): void {
    this.isWatch = true;
    this.isRead = false;
    this.isListen = false;
  }
  selectListen(): void {
    this.isListen = true;
    this.isRead = false;
    this.isWatch = false;
  }
}
