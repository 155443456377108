import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { Sweet } from './sweet';
import { SWEETS } from './sweets';
import { SAVORYITEMS } from './savory-items';
import { BARS } from './bars';
import { ArrowComponent } from '../../../shared/arrow/arrow.component';

@Component({
  selector: 'app-mpls-eat',
  standalone: true,
  imports: [CommonModule, ArrowComponent],
  templateUrl: './eat.component.html',
  styleUrls: ['./eat.component.scss']
})
export class MPLSEatOutsideComponent {
  sweets = SWEETS;
  savoryItems = SAVORYITEMS;
  bars = BARS;
  isSweet = false;
  isSavory = false;
  isLiquids = false;

  constructor(private router: Router) { }

  back(): void {
    this.router.navigate(['/minneapolis/outside']);
  }

  selectSweet(): void {
    this.isSweet = true;
    this.isSavory = false;
    this.isLiquids = false;
  }
  selectSavory(): void {
    this.isSavory = true;
    this.isSweet = false;
    this.isLiquids = false;
  }
  selectLiquids(): void {
    this.isLiquids = true;
    this.isSweet = false;
    this.isSavory = false;
  }

}
