<section class="section">
	<div class="container">
		<app-arrow url="/elsewhere"></app-arrow>
		<h1 class="title">Would You Prefer A Game That Is:</h1>
		<div class="columns">
			<div class="column">
				<div class="card content" (click)="selectTrashTalk()">
					<h3>Heavy On the Trash Talk</h3>
				</div>
			</div>
			<div class="column">
				<div class="card content" (click)="selectCivil()">
					<h3>Pretty Civil</h3>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="section" style="padding-top:0;padding-bottom:0;">
	<div class="container">
		<hr />
	</div>
</section>
<section class="section">
	<div class="container">
		<div class="games games--trash-talk" *ngIf="isTrashTalk">
			<h2 class="title is-4">Check Out:</h2>
			<div class="columns">

				<div class="column">
					<a href="https://www.youtube.com/watch?v=Z5BTSevuuqs" target="_blank">
						<div class="card content">
							<h3>Spades</h3>
						</div>
					</a>
				</div>

				<div class="column">
					<a
						href="https://brilliantorbs.com/collections/trivia-party-board-games-for-friends-and-family/products/brilliant-or-bs-original-edition"
						target="_blank">
						<div class="card content">
							<h3>Brilliant or BS?</h3>
						</div>
					</a>
				</div>

			</div>
		</div>

		<div class="games games--civil" *ngIf="isCivil">
			<h2 class="title is-4">Check Out:</h2>
			<div class="columns">

				<div class="column">
					<a href="https://hearditallbeforegame.com/" target="_blank">
						<div class="card content">
							<h3>Heard It All Before</h3>
						</div>
					</a>
				</div>

				<div class="column">
					<a href="https://hellawkward.com/" target="_blank">
						<div class="card content">
							<h3>Hella Awkward</h3>
						</div>
					</a>
				</div>

				<div class="column">
					<a
						href="https://www.playblackwallstreet.com/products/black-wall-street-the-board-game-second-edition"
						target="_blank">
						<div class="card content">
							<h3>Black Wall Street</h3>
						</div>
					</a>
				</div>

			</div>
		</div>

	</div>
</section>
