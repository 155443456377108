<section class="section" *ngIf="!isCategory">
	<div class="container">
		<app-arrow url="/"></app-arrow>
		<h1 class="title">Would You Like To:</h1>

		<div class="columns is-multiline">
			<div class="column is-half">
				<a routerLink="/minneapolis/inside" (click)="isCategory = true">
					<div class="card content inside">
						<h3>Hibernate</h3>
					</div>
				</a>
			</div>

			<div class="column is-half">
				<a href="https://maps.app.goo.gl/g2P2iCL2CDSJUzjV8?g_st=i" rel="noopener" target="_blank"
					class="link">
					<div class="card content outside">
						<h3>Increase Your Melanin</h3>
					</div>
				</a>
			</div>

		</div>
	</div>
</section>
<router-outlet></router-outlet>
