import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MOVIES } from './movies';
import { EVENTS } from './events';
import { ArrowComponent } from '../../../shared/arrow/arrow.component';

@Component({
  selector: 'app-learn',
  standalone: true,
  imports: [CommonModule, ArrowComponent],
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.scss']
})
export class LearnOutsideComponent {
  movies = MOVIES;
  events = EVENTS;
  isWatch = false;
  isListen = false;

  constructor(private router: Router) { }

  back(): void {
    this.router.navigate(['/los-angeles/outside']);
  }

  selectWatch(): void {
    this.isWatch = true;
    this.isListen = false;
  }
  selectListen(): void {
    this.isListen = true;
    this.isWatch = false;
  }
}
