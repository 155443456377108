import { Bar } from './bar';

export const BARS: Bar[] = [
	{
		id: 1,
		name: 'Cobble Social House',
		link: 'https://www.instagram.com/cobblempls/?utm_source=ig_embed&ig_rid=558225aa-9edd-4937-8d20-947a2f220b85'
	}, {
		id: 2,
		name: 'Dogwood Coffee Co',
		link: 'https://www.instagram.com/dogwoodcoffee/?hl=en'
	}, {
		id: 3,
		name: 'Du Nord Social Spirits',
		link: 'https://dunordsocialspirits.com/events'
	}, {
		id: 4,
		name: 'The Dripping Root',
		link: 'https://www.instagram.com/thedrippingroot/?hl=en'
	}, {
		id: 5,
		name: 'Palmer’s Bar',
		link: 'https://palmers-bar.com/?view=calendar&month=06-2023'
	}, {
		id: 6,
		name: 'The Get Down Coffee Co.',
		link: 'https://www.instagram.com/thegetdowncoffeeco/'
	}
];
