import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LosAngelesComponent } from './los-angeles/los-angeles.component';
import { MinneapolisComponent } from './minneapolis/minneapolis.component';
// import { ArrowComponent } from './shared/arrow/arrow.component';
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HomeComponent,
        LosAngelesComponent,
        MinneapolisComponent
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
