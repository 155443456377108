import { Savory } from './savory';

export const SAVORYITEMS: Savory[] = [
	{
		id: 1,
		name: 'STEPCHLD',
		link: 'https://www.instagram.com/step_chld/?utm_source=ig_embed&ig_rid=97c5fed9-f776-4b53-adad-e43b295fe265'
	}, {
		id: 2,
		name: 'Pimento Kitchen',
		link: 'https://pimento.com/minneapolis-pimento-kitchen-food-menu'
	}, {
		id: 3,
		name: 'Tommie’s Pizza',
		link: 'https://www.instagram.com/tommiespizza/'
	}, {
		id: 4,
		name: 'Handsome Hog',
		link: 'https://handsomehog.com/#menus'
	}
];
