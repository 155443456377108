import { Book } from './book';

export const BOOKS: Book[] = [
	{
		id: 1,
		title: 'Four Hundred Souls',
		author: 'Ibram X. Kendhi & Keisha N.',
		link: 'https://rep.club/products/four-hundred-souls?_pos=1&_sid=b8ad0f833&_ss=r'
	}, {
		id: 2,
		title: 'The 1619 Project',
		author: 'Nikole Hannah-Jones',
		link: 'https://rep.club/products/1619-project-book?_pos=2&_sid=eb9f8a0e7&_ss=r'
	}, {
		id: 3,
		title: 'Between the World and Me',
		author: 'Ta-Nehisi Coates',
		link: 'https://rep.club/products/between-the-world-and-me?_pos=1&_sid=9f3501b9b&_ss=r'
	}, {
		id: 4,
		title: 'Homegoing',
		author: 'Yaa Gyasi',
		link: 'https://rep.club/products/homegoing-yaa-gyasi?_pos=1&_sid=13c86e9e7&_ss=r'
	}, {
		id: 5,
		title: 'Dear Martin',
		author: 'Nic Stone',
		link: 'https://rep.club/products/dear-martin-nic-stone?_pos=2&_sid=d8db52ec4&_ss=r'
	}, {
		id: 6,
		title: 'Hell of A Book',
		author: 'Jason Mott',
		link: 'https://rep.club/products/hell-of-a-book?_pos=1&_sid=57e36e3ed&_ss=r'
	}, {
		id: 7,
		title: 'Children of Blood and Bone',
		author: 'Tomi Adeyemi',
		link: 'https://rep.club/products/children-of-blood-bone?_pos=3&_sid=cd0904bdc&_ss=r'
	}, {
		id: 8,
		title: 'All Different Now',
		author: 'Angela Johnson',
		link: 'https://www.simonandschuster.com/books/All-Different-Now/Angela-Johnson/9780689873768'
	}, {
		id: 9,
		title: 'A Flag for Juneteenth',
		author: 'Kim Taylor',
		link: 'https://www.penguinrandomhouse.com/books/714406/a-flag-for-juneteenth-by-written-and-illustrated-by-kim-taylor/'
	}
];
