import { Component } from '@angular/core';
import { Router, NavigationEnd, Event, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'zmbz-juneteenth';
  private classes: Array<string> = [];

  removeBodyClasses() {
    this.classes.map((key: string) => {
      if (key.length > 0) {
        document.body.classList.remove(key + '-page');
      }
    });
  }

  addBodyClasses(path: any) {
    this.classes = path.replace(/\/[0-9]+/g, '').split('?')[0].split('/');
    this.classes.map((key: string) => {

      if (key.length > 0) {
        document.body.classList.add(key + '-page');
      }

      if (key.length === 0) {
        document.body.classList.add('home-page');
      } else {
        document.body.classList.remove('home-page');
      }

    });
  }
  constructor(private route: ActivatedRoute,
    private router: Router) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.removeBodyClasses();
        this.addBodyClasses(event.url.split('?')[0].split('/')[1]);
      }
    });
  }
}
