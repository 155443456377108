import { Movie } from './movie';

export const MOVIES: Movie[] = [
	{
		id: 1,
		title: 'Left Out',
		streaming: 'YouTube',
		link: 'https://www.youtube.com/watch?v=ubd2cnslP8A'
	}, {
		id: 2,
		title: 'American Son',
		streaming: 'Netflix',
		link: 'https://www.youtube.com/watch?v=dbKZlSwAS3M'
	}, {
		id: 3,
		title: 'Whose Streets?',
		streaming: 'Amazon Prime/Tubi',
		link: 'https://www.youtube.com/watch?v=upiJnjJSerw'
	}, {
		id: 4,
		title: 'Amend: The Fight for America',
		streaming: 'Netflix',
		link: 'https://www.youtube.com/watch?v=1S01VszVIt0'
	}, {
		id: 5,
		title: 'Black Barbie',
		streaming: 'Netflix',
		link: 'https://www.youtube.com/watch?v=Itsg2V5PSkI'
	}, {
		id: 1,
		title: 'Civil: Ben Crump',
		streaming: 'Netflix',
		link: 'https://www.youtube.com/watch?v=ZI9_udV9RWQ'
	}, {
		id: 2,
		title: 'Miss Juneteenth',
		streaming: 'Amazon + YouTube',
		link: 'https://www.youtube.com/watch?v=Vb3oREG_DdA'
	}, {
		id: 3,
		title: 'High on the Hog',
		streaming: 'Netflix',
		link: 'https://www.youtube.com/watch?v=7wsEdxt1Ico'
	}, {
		id: 4,
		title: 'Two Distant Strangers',
		streaming: 'Netflix',
		link: 'https://www.youtube.com/watch?v=j-67K-KwHbA'
	}
];
