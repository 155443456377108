import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ArrowComponent } from '../../shared/arrow/arrow.component';

@Component({
  selector: 'app-e-eat',
  templateUrl: './eat.component.html',
  styleUrls: ['./eat.component.scss'],
  standalone: true,
  imports: [ArrowComponent]
})
export class EEatComponent {
  constructor(private router: Router) { }

  back(): void {
    this.router.navigate(['/minneapolis/inside']);
  }

}
