<section class="section">
	<div class="container">
		<app-arrow url="/minneapolis/outside"></app-arrow>
		<h1 class="title hidden">would you like to:</h1>
		<div class="columns is-multiline">
			<div class="column is-one-third" *ngFor="let e of events">
				<a href="{{e.link}}" target="_blank">
					<div class="card content">
						<h3>{{e.name}}</h3>
						<p *ngIf="e.copy">{{e.copy}}</p>
						<small *ngIf="e.date">{{e.date}}</small>
					</div>
				</a>
			</div>

		</div>
	</div>
</section>
