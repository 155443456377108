<section class="section" *ngIf="!isCategory">
	<div class="container">
		<app-arrow url="/"></app-arrow>
		<h1 class="title">Would You Like To:</h1>

		<div class="columns">
			<div class="column">
				<a routerLink="/elsewhere/eat" (click)="isCategory = true">
					<div class="card content eat">
						<h3>Feast</h3>
					</div>
				</a>
			</div>

			<div class="column">
				<a routerLink="/elsewhere/play" (click)="isCategory = true">
					<div class="card content play">
						<h3>Vibe</h3>
					</div>
				</a>
			</div>

			<div class="column">
				<a routerLink="/elsewhere/learn" (click)="isCategory = true">
					<div class="card content learn">
						<h3>Discover</h3>
					</div>
				</a>
			</div>

		</div>

	</div>
</section>

<router-outlet></router-outlet>
