import { Movie } from './movie';

export const MOVIES: Movie[] = [
	{
		id: 1,
		title: 'Street Food Cinema',
		copy: 'Do The Right Thing Screening',
		link: 'https://www.streetfoodcinema.com/schedule',
		date: 'June 17'
	}
];
