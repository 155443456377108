import { Sweet } from './sweet';

export const SWEETS: Sweet[] = [
	{
		id: 1,
		name: 'Fleurs Et Sel',
		link: 'https://www.instagram.com/fleursetsel/'
	}, {
		id: 2,
		name: 'Big Man Bakes',
		link: 'http://bigmanbakes.com/cupcakes.htm'
	}, {
		id: 3,
		name: 'Thrive Donuts',
		link: 'https://www.thrivedoughnuts.com/findus/'
	}, {
		id: 5,
		name: 'Pies for Justice',
		link: 'https://www.andgatherforgood.com/piesforjustice',
		date: 'June 15'
	}
];
