import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ArrowComponent } from '../../../shared/arrow/arrow.component';

@Component({
  selector: 'app-eat',
  templateUrl: './eat.component.html',
  styleUrls: ['./eat.component.scss'],
  standalone: true,
  imports: [ArrowComponent]
})
export class EatComponent {
  constructor(private router: Router) { }

  back(): void {
    this.router.navigate(['/los-angeles/inside']);
  }

}
